import { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';

import { GalleryFilterContext } from 'components/Gallery/GalleryFilter';

export const useFollowingPaths = ({ allSitePageEdges, currentPath }) => {
  const { activeGalleryFilter } = useContext(GalleryFilterContext);

  const pages = activeGalleryFilter
    ? allSitePageEdges.filter(({ node: { path } }) => path.includes(activeGalleryFilter))
    : allSitePageEdges;

  const currentIndex = pages.findIndex(({ node: { path } }) => path === currentPath);
  const prevPath = pages[currentIndex - 1]?.node.path;
  const nextPath = pages[currentIndex + 1]?.node.path;

  return {
    prevPath,
    nextPath,
  };
};

export const useNavigateWithArrows = ({ nextPath, prevPath }) => {
  useEffect(() => {
    const handleKeyDown = e => {
      const ARROW_RIGHT = 39;
      const ARROW_LEFT = 37;
      switch (e.keyCode) {
        case ARROW_RIGHT:
          navigate(nextPath);
          break;

        case ARROW_LEFT:
          navigate(prevPath);
          break;

        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};
