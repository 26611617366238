import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import leftSvg from 'assets/icons/left.svg';
import rightSvg from 'assets/icons/right.svg';
import Icon from 'components/Icon';
import { GalleryFilterContext } from 'components/Gallery/GalleryFilter';
import { getCategoryName } from 'components/Gallery/GalleryFilter/utils';

import { galeria as galeriaUrl } from '../../urls';

import { TransitionImage } from './components/Transition';
import {
  LinksWrapper,
  StyledBottomOrRight,
  StyledItem,
  StyledItemContent,
  StyledItemTitle,
  StyledTransitionLink,
  StyledTopOrLeft,
  StyledWrapper,
  StyledKeyboardHint,
} from './styled';
import { useFollowingPaths, useNavigateWithArrows } from './hooks';
import { columnMap, columnNames, transitionProps } from './constants';

const Content = React.memo(props => {
  const { activeGalleryFilter } = useContext(GalleryFilterContext);
  const galleryFilterName = getCategoryName(activeGalleryFilter);

  const { currentPath, googleSheetsDataJson, allSitePageEdges, image } = props;

  const { nextPath, prevPath } = useFollowingPaths({ allSitePageEdges, currentPath });

  const fluid = image?.childImageSharp.fluid;

  useNavigateWithArrows({
    nextPath,
    prevPath,
  });

  return (
    <StyledWrapper>
      <StyledTopOrLeft>
        <StyledTransitionLink $mobileLink to={galeriaUrl}>
          <Icon size={18} svg={leftSvg} />
          Wszystkie {galleryFilterName}
        </StyledTransitionLink>

        <TransitionImage fluid={fluid} image={image} />
        <LinksWrapper>
          {prevPath ? (
            <StyledTransitionLink $isPrev to={prevPath} {...transitionProps}>
              <Icon size={18} svg={leftSvg} />
              Poprzedni
            </StyledTransitionLink>
          ) : (
            <div />
          )}
          <StyledTransitionLink $goToGallery to={galeriaUrl}>
            Wszystkie {galleryFilterName}
          </StyledTransitionLink>
          {nextPath ? (
            <StyledTransitionLink $isNext to={nextPath} {...transitionProps}>
              Następny <Icon size={18} svg={rightSvg} />
            </StyledTransitionLink>
          ) : (
            <div />
          )}
        </LinksWrapper>

        <StyledKeyboardHint>
          Wskazówka: możesz przewijać zdjęcia używając strzałek na klawiaturze
        </StyledKeyboardHint>
      </StyledTopOrLeft>
      <StyledBottomOrRight>
        {columnNames.map(
          columnName =>
            googleSheetsDataJson[columnName] && (
              <StyledItem key={columnName}>
                <StyledItemTitle>{columnMap[columnName]}</StyledItemTitle>
                <StyledItemContent>
                  {columnName === 'link' ? (
                    <a href={googleSheetsDataJson[columnName]} target="_blank" rel="noreferrer">
                      {googleSheetsDataJson[columnName]}
                    </a>
                  ) : (
                    googleSheetsDataJson[columnName]
                  )}
                </StyledItemContent>
              </StyledItem>
            ),
        )}
      </StyledBottomOrRight>
    </StyledWrapper>
  );
});

Content.propTypes = {
  currentPath: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object,
    }),
  }),
  allSitePageEdges: PropTypes.array,
  googleSheetsDataJson: PropTypes.shape({
    ordinalNumber: PropTypes.string,
    filename: PropTypes.string,
    category: PropTypes.string,
    author: PropTypes.string,
    title: PropTypes.string,
    newspaperDetails: PropTypes.string,
    birthdate: PropTypes.string,
    birthplace: PropTypes.string,
    publisher: PropTypes.string,
    link: PropTypes.string,
    source: PropTypes.string,
    rights: PropTypes.string,
    signature: PropTypes.string,
  }),
};

export default Content;
