import React from 'react';
import PropTypes from 'prop-types';

import WithTransition from 'components/WithTransition';

import { StyledGatsbyImage, StyledImageWrapper } from './styled';

const TransitionImage = React.memo(({ fluid, image }) => {
  const aspectRatio = image?.childImageSharp.fluid.aspectRatio;

  return (
    <React.Fragment>
      <StyledGatsbyImage
        $aspectRatio={aspectRatio}
        $presentationWidth={image?.childImageSharp.fluid.presentationWidth}
        fluid={fluid}
      />
    </React.Fragment>
  );
});

TransitionImage.propTypes = {
  fluid: PropTypes.object,
  image: PropTypes.object,
};

export default WithTransition(TransitionImage, StyledImageWrapper);
