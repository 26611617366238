export const columnMap = {
  category: 'Kategoria',
  author: 'Autor',
  title: 'Tytuł',
  newspaperDetails: 'Tytuł, numer i strona czasopisma',
  birthdate: 'Data powstania',
  birthplace: 'Miejsce powstania',
  publisher: 'Wydawca',
  link: 'Link',
  source: 'Źródło',
  signature: 'Sygnatura',
  rights: 'Prawa',
};

export const columnNames = Object.keys(columnMap);

export const transitionProps = {
  exit: {
    length: 0.23,
  },
  entry: {
    length: 0.23,
  },
};
