import styled, { css } from 'styled-components';
import Image from 'gatsby-image';

export const StyledGatsbyImage = styled(Image)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 100% !important;
  max-width: 660px;
  width: 100% !important;

  ${({ theme, $presentationWidth }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      ${$presentationWidth &&
      css`
        width: ${$presentationWidth}px !important;
      `}
    }
  `}
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  margin: auto;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      margin-top: 40px;
      margin-bottom: auto;
    }
  `}
`;
