import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Content from './Content';

const GalleryItem = ({
  data: {
    googleSheetsDataJson,
    allSitePage: { edges: allSitePageEdges },
    image,
  },
  path: currentPath,
}) => (
  <React.Fragment>
    <Helmet>
      <body className="beige" />
    </Helmet>
    <Content
      currentPath={currentPath}
      googleSheetsDataJson={googleSheetsDataJson}
      allSitePageEdges={allSitePageEdges}
      image={image}
    />
  </React.Fragment>
);

GalleryItem.propTypes = {
  path: PropTypes.string,
  data: PropTypes.shape({
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    allSitePage: PropTypes.shape({
      edges: PropTypes.array,
    }),
    googleSheetsDataJson: PropTypes.shape({
      ordinalNumber: PropTypes.string,
      filename: PropTypes.string,
      category: PropTypes.string,
      author: PropTypes.string,
      title: PropTypes.string,
      newspaperDetails: PropTypes.string,
      birthdate: PropTypes.string,
      birthplace: PropTypes.string,
      publisher: PropTypes.string,
      link: PropTypes.string,
      source: PropTypes.string,
      rights: PropTypes.string,
      signature: PropTypes.string,
    }),
  }),
};

export default GalleryItem;

export const query = graphql`
  query data($category: String!, $filename: String!, $eqRelativePath: String!) {
    allSitePage(filter: { path: { regex: "/galeria/.+/" } }) {
      edges {
        node {
          path
          isCreatedByStatefulCreatePages
        }
      }
    }

    image: file(relativePath: { eq: $eqRelativePath }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
          presentationHeight
          presentationWidth
          aspectRatio
        }
      }
    }

    googleSheetsDataJson(category: { eq: $category }, filename: { eq: $filename }) {
      ordinalNumber
      filename
      category
      author
      title
      newspaperDetails
      birthdate
      birthplace
      publisher
      link
      source
      rights
      signature
    }
  }
`;
