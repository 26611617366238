import styled, { css } from 'styled-components';

import Container from 'components/Container';
import { TransitionLink } from 'components/Link';
import Icon from 'components/Icon';

export const StyledWrapper = styled(Container)`
  margin-bottom: 50px;

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 60% 1fr;
      }
    `}
`;

export const StyledTopOrLeft = styled.div`
  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        /*display: flex;
        flex-direction: column;
        justify-content: center;*/
      }
    `}
`;

export const LinksWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        margin-top: 16px;
        grid-template-columns: 1fr auto 1fr;
      }
    `}
`;

export const StyledTransitionLink = styled(TransitionLink)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.font.size18};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Icon} {
    margin-right: 12px;
  }

  ${({ $mobileLink }) =>
    $mobileLink &&
    css`
      justify-content: flex-start;
      margin-top: 16px;
      margin-bottom: 8px;

      ${({ theme }) =>
        css`
          ${theme.mediaQueries.smallDesktop.min} {
            display: none;
          }
        `}
    `}

  ${({ $goToGallery }) =>
    $goToGallery &&
    css`
      display: none;

      ${({ theme }) =>
        css`
          ${theme.mediaQueries.smallDesktop.min} {
            display: block;
          }
        `}
    `}

  ${({ $isPrev }) =>
    $isPrev &&
    css`
      justify-content: flex-start;
    `}

  ${({ $isNext }) =>
    $isNext &&
    css`
      justify-content: flex-end;

      ${Icon} {
        margin-left: 12px;
        margin-right: 0;
      }
    `}
`;

export const StyledBottomOrRight = styled.div`
  margin-top: 40px;
`;

export const StyledItem = styled.div`
  margin-bottom: 16px;
`;

export const StyledItemTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.font.size18};
  text-transform: uppercase;
`;

export const StyledItemContent = styled.p`
  font-size: ${({ theme }) => theme.font.size18};

  a {
    @media (any-hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.colors.dark};
      }
    }
  }
`;

export const StyledKeyboardHint = styled.div`
  display: none;

  ${({ theme }) =>
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        display: block;
        margin-top: 8px;
        font-style: italic;
        color: ${theme.colors.dark};
        font-size: ${theme.font.size16};
      }
    `}
`;
