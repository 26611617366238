import React from 'react';
import { TransitionState } from 'gatsby-plugin-transition-link';
import { Spring } from 'react-spring/renderprops';

const WithTransition = (WrappedComponent, MainComponent) => props => (
  <TransitionState>
    {({ mount, current }) => {
      const ms = current.length * 1000;

      return (
        <Spring
          to={{
            transform: `translateX(${mount ? 0 : '9px'})`,
            opacity: mount ? 1 : 0,
          }}
          config={{
            duration: ms,
          }}
        >
          {springProps => (
            <MainComponent style={springProps}>
              <WrappedComponent {...props} />
            </MainComponent>
          )}
        </Spring>
      );
    }}
  </TransitionState>
);

export default WithTransition;
